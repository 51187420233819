* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: background-color .2s;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  color: #555;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  line-height: 1;
  transition: background-color .2s;
  position: relative;
  overflow-x: hidden;
}

::selection {
  background-color: #5fb1ade6;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.subheading {
  color: #4c8e8a;
  letter-spacing: .8px;
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  font-weight: 600;
}

.heading-secondary {
  color: #333;
  letter-spacing: -.5px;
  margin-bottom: 6.4rem;
  font-size: 5.2rem;
  font-weight: 700;
  line-height: 1;
}

.heading-tertiary {
  color: #444;
  margin-bottom: 3.2rem;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.2;
}

.grid {
  display: grid !important;
}

.grid--2-cols {
  grid-template-columns: 1fr 1fr;
}

.grid--3-cols {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid--center-v {
  align-items: center;
}

.grid--2-erows {
  grid-template-rows: 1fr 1fr;
}

.text-center {
  text-align: center;
}

.margin-bottom-med {
  margin-bottom: 4.8rem !important;
}

.margin-bottom-big {
  margin-bottom: 8rem;
}

.dark-img {
  opacity: .9;
  box-shadow: 0 3px 6px #00000013;
  filter: brightness() !important;
}

.main-header {
  background-color: #eff7f7;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 3.2rem;
  transition: all .2s;
  display: flex;
  position: relative;
  height: 80px !important;
}

.logo {
  height: 7.5rem;
}

.name:link, .name:visited {
  color: #5fb1ad;
  letter-spacing: -1px;
  font-family: Allerta Stencil, sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
  text-decoration: none;
}

.big-nav {
  place-items: center;
  gap: 3.2rem;
  display: flex;
}

.main-nav-list {
  gap: 4.8rem;
  list-style: none;
  display: flex;
}

.main-nav-links:link, .main-nav-links:visited {
  color: #333;
  letter-spacing: -1px;
  font-size: 2.4rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s;
}

.main-nav-links:hover, .main-nav-links:active {
  color: #5fb1ad;
  border-bottom: 2px solid;
}

.sticky .main-header {
  z-index: 999;
  width: 100%;
  height: 8rem;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  box-shadow: 0 1.2rem 3.2rem #00000008;
}

.sticky .section-hero {
  margin-top: 80px !important;
}

.btn-mobile-nav {
  cursor: pointer;
  background: none;
  border: none;
  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

.section-hero {
  background-color: #eff7f7;
  padding: 4.8rem 0 8rem;
}

.header-hero {
  max-width: 130rem;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 8rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
}

.hero-text {
  color: #333;
  letter-spacing: -5px;
  margin-bottom: 4.8rem;
  font-size: 8.6rem;
  font-weight: 700;
  line-height: .9;
}

.hero-sub-text {
  color: #555;
  font-size: 3rem;
  line-height: 1.4;
}

.hero-img {
  width: 90%;
}

.section-services {
  padding: 8rem 0 0;
  overflow: hidden;
}

.services {
  max-width: 100vw;
}

.service-text-box {
  width: 100%;
  padding: 0 3.2rem 0 6.4rem;
}

.appointment {
  font-size: 2rem;
  font-weight: 400;
}

.pilates-list {
  flex-direction: column;
  gap: 2.4rem;
  list-style: none;
  display: flex;
}

.pilates-list li {
  color: #555;
  align-items: center;
  gap: 1.2rem;
  font-size: 2.4rem;
  line-height: 1.4;
  display: flex;
}

.pilates-list li span {
  text-align: left;
}

.service-check {
  color: #5fb1ad;
  font-size: 2.4rem;
}

.service-img-box {
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  padding: 4.8rem 6.4rem;
  display: grid;
  position: relative;
}

.service-img-box:before {
  content: "";
  width: 200%;
  height: 100%;
  z-index: -1;
  background-color: #dfefef;
  position: absolute;
}

.left.service-img-box:before {
  right: 0;
}

#left-img {
  width: 110%;
  filter: drop-shadow(0 3px 6px #00000013);
}

.service-img-box img {
  width: 85%;
}

.service-img-box-1 {
  filter: drop-shadow(0 3px 4px #0000001a);
}

.service-img-box-2 {
  filter: drop-shadow(0 3px 6px #0000001a);
  grid-area: 2 / 2;
  margin-top: -45%;
  margin-left: -45%;
}

.service-subheading {
  color: #333;
  letter-spacing: -.5px;
  margin-bottom: 4.8rem;
  font-size: 4.4rem;
  font-weight: 700;
  line-height: 1.2;
}

.physio {
  background-image: linear-gradient(to bottom right, #afd8d6, #6fb9b5);
  border-radius: 5px;
  margin: 0 4.8rem 4.8rem;
}

.physio-container {
  grid-template-columns: 40fr 60fr;
  align-items: center;
  column-gap: 2.4rem;
  display: grid;
}

.physio-img-box {
  gap: 1.2rem;
  padding: 4.8rem;
}

.physio-img {
  overflow: hidden;
}

.physio-img img {
  width: 110%;
  transition: all .3s;
}

.physio-img img:hover {
  transform: scale(1.1);
}

.physio-text-container {
  padding: 8rem 2.4rem;
}

.physio-text-box {
  gap: 3.2rem;
  display: flex;
}

.physio .service-check {
  color: #555;
}

.physio-subheading {
  color: #444;
  margin-bottom: 2.4rem;
  font-size: 3rem;
  font-weight: 700;
}

.physio-list {
  flex-direction: column;
  gap: 1.6rem;
  list-style: none;
  display: flex;
}

.physio-list li {
  color: #555;
  align-items: center;
  gap: 1.2rem;
  font-size: 2rem;
  line-height: 1.6;
  display: flex;
}

.section-about-us {
  padding: 3.2rem 3.2rem 8rem;
  position: relative;
}

.about-us-content {
  align-items: center;
  margin-bottom: 4.8rem;
}

.about-us-content-textbox {
  width: 100%;
  font-size: 2rem;
  line-height: 1.8;
}

.about-us-img-box {
  justify-content: center;
  align-items: center;
  display: flex;
}

.about-us-img {
  width: 75%;
}

.about-us {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8rem;
  padding-top: 4.8rem;
  display: flex;
}

.about-us-text {
  color: #132323;
  letter-spacing: -1.5px;
  align-items: center;
  gap: 3.2rem;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.6;
  display: flex;
}

.about-us-icon {
  height: 4rem;
  width: 4rem;
  fill: #132323;
}

.about-us:before, .about-us:after {
  content: "";
  width: 45%;
  height: 12.5%;
  z-index: -1;
  background-color: #8fc8c6;
  position: absolute;
  bottom: 47%;
}

.about-us:before {
  border-top-right-radius: 300px;
  border-bottom-right-radius: 300px;
  left: 0;
}

.about-us:after {
  border-top-left-radius: 300px;
  border-bottom-left-radius: 300px;
  right: 0;
}

.contact-box {
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  gap: 4.8rem;
  font-size: 2rem;
  font-weight: 500;
  display: grid;
}

.contact-heading {
  color: #444;
  margin-bottom: 4.8rem;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.2;
}

.contact {
  width: 100%;
  font-size: 2rem;
}

.contact-icon {
  color: #5fb1ad;
  margin-bottom: 1.6rem;
  font-size: 3rem;
}

.contact-link:link, .contact-link:visited {
  color: #555;
  align-items: center;
  gap: 1.2rem;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.contact-link:hover, .contact-link:active {
  color: #333;
}

.contact {
  flex-direction: column;
  gap: 1.6rem;
  display: flex;
}

.timing {
  font-weight: 400;
}

.timing-title {
  letter-spacing: -.5px;
  font-weight: 600;
}

.home-visit {
  font-weight: 500;
}

.address {
  line-height: 1.6;
}

.address-link:link, .address-link:visited {
  color: inherit;
}

.shadow {
  width: 100%;
  height: 100%;
  border: 1px solid #2220;
  border-radius: 10px;
  padding: 2.4rem;
  transition: all .3s;
  box-shadow: 0 5px 10px #0000000d;
}

.shadow:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 8px 20px #0000000d;
}

.footer {
  color: #767676;
  border-top: 1px solid #eee;
  padding: 8rem 3.2rem;
  font-size: 1.4rem;
  line-height: 1.8;
}

.footer-container {
  width: 70%;
}

.grid--footer {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3.2rem;
}

.footer-logo-img {
  width: 67%;
}

.footer-heading {
  color: #555;
  margin-bottom: 3.2rem;
  font-size: 1.8rem;
  font-weight: 600;
}

.footer-link:link, .footer-link:visited {
  color: #767676;
  letter-spacing: .5px;
  align-items: center;
  gap: .8rem;
  text-decoration: none;
  display: flex;
}

.footer-link:hover, .footer-link:active {
  color: #666;
}

.footer-con-text {
  flex-direction: column;
  gap: 2.4rem;
  display: flex;
}

.footer-add {
  font-style: normal;
}

.footer-add a:link, .footer-add a:visited, .footer-add a:hover, .footer-add a:active {
  color: inherit;
}

.footer-con {
  flex-direction: column;
  display: flex;
}

.footer-icons {
  gap: 1.6rem;
  margin-bottom: .8rem;
  font-size: 1.8rem;
  display: flex;
}

.footer-nav {
  margin-left: 3.2rem;
}

.footer-nav-list {
  flex-direction: column;
  gap: 1.6rem;
  font-size: 1.8rem;
  list-style: none;
  display: flex;
}

.footer-nav-list .footer-link:link, .footer-nav-list .footer-link:visited {
  font-weight: 500;
}

.copyright {
  color: #767676;
  text-align: center;
  margin-top: 1.6rem;
  font-size: 1.2rem;
}

.darkmode {
  background-color: #fff;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding-right: 12rem;
  display: flex;
  position: fixed;
  bottom: 3.6rem;
  right: 0;
  transform: translateX(55%);
  box-shadow: 0 1rem 2rem #0000001a;
}

.dark .darkmode {
  background-color: #000;
  border: 2px solid #222;
  box-shadow: 0 0 #ffffff26;
}

.dark-mode-btn {
  height: 5.5rem;
  width: 5.5rem;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dark .dark-mode-btn {
  background-color: #000;
}

.dm-btn-icon {
  color: #5fb1ad;
  font-size: 4.4rem;
}

.dm-btn-icon[name="sunny-outline"] {
  display: none;
}

.dm-btn-icon[name="moon-outline"], .dark .dm-btn-icon[name="sunny-outline"] {
  display: block;
}

.dark .dm-btn-icon[name="moon-outline"] {
  display: none;
}

.dark {
  color: #ababab;
  background-color: #000;
}

.dark .icon-mobile-nav {
  color: #e9ecef;
}

.dark .main-header, .dark .main-nav {
  background-color: #1e2424;
}

.dark .main-nav-links:link, .dark .main-nav-links:visited {
  color: #fff;
}

.dark .main-nav-links:hover, .dark .main-nav-links:active {
  color: #5fb1ad;
  border-bottom: 2px solid;
}

.dark .section-hero {
  background-color: #1e2424;
}

.dark .hero-text, .dark .heading-secondary, .dark .service-subheading {
  color: #e9ecef;
}

.dark .physio {
  background-image: linear-gradient(to bottom right, #6fb9b5, #4c8e8a);
}

.dark .physio-text-container .service-subheading {
  color: #333;
}

.dark .hero-sub-text {
  color: #adb5bd;
}

.dark .heading-tertiary {
  color: #cacaca;
}

.dark .pilates-list li, .dark .contact-link:link, .dark .contact-link:visited {
  color: #ababab;
}

.dark .about-us:after, .dark .about-us:before {
  background-color: #569f9c;
}

.dark .footer {
  color: #919191;
  border-top: 1px solid #282828;
}

.dark .footer-heading {
  color: #b5b5b5;
}

.dark .shadow {
  border: 1px solid #222;
  box-shadow: 0 0 #ffffff21;
}

.dark .shadow:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 0 #22222280;
}

.dark .service-img-box:before {
  background-color: #569f9c;
}

/*# sourceMappingURL=index.701e40bf.css.map */
