/* 
--- 01 TYPOGRAPHY SYSTEMS

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights:
Default: 400

- Line heights:
Default: 1
For big: 0.9
For medium: 1.4

--- 02 COLORS

- Primary: #5FB1AD

- Tints: #eff7f7, #dfefef, #afd8d6, #8fc8c6, #6fb9b5

- Shades: #4c8e8a

- Greys: #555 #333 #767676

- Dark mode: #e9ecef, #cacaca, #ababab, #adb5bd, #919191, #b5b5b5, #343a40

--- Image sources:

- Drawkit

--- 05 SHADOWS

--- 06 BORDER RADIUS

--- 07 WHITESPACE

- Spacing system (px):
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: background-color 0.2s;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1;
  color: #555;
  overflow-x: hidden;
  position: relative;
  transition: background-color 0.2s;
}

/* ********************* */
/* GENERAL STYLES */
/* ********************* */

::selection {
  background-color: rgb(95, 177, 173, 0.9);
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.subheading {
  color: #4c8e8a;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.heading-secondary {
  font-size: 5.2rem;
  font-weight: 700;
  color: #333;
  line-height: 1;
  letter-spacing: -0.5px;
  margin-bottom: 6.4rem;
}

.heading-tertiary {
  font-size: 3.6rem;
  font-weight: 700;
  color: #444;
  line-height: 1.2;
  /* letter-spacing: -0.5px; */
  margin-bottom: 3.2rem;
}

.grid {
  display: grid !important;
}

.grid--2-cols {
  grid-template-columns: 1fr 1fr;
}

.grid--3-cols {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid--center-v {
  align-items: center;
}

.grid--2-erows {
  grid-template-rows: 1fr 1fr;
}

.text-center {
  text-align: center;
}

.margin-bottom-med {
  margin-bottom: 4.8rem !important;
}

.margin-bottom-big {
  margin-bottom: 8rem;
}

.dark-img {
  filter: brightness(100%) !important;
  /* filter: contrast(100%) !important; */
  opacity: 90%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.075);
}

/* ********************* */
/* HEADER & HERO */
/* ********************* */

.main-header {
  background-color: #eff7f7;
  height: 80px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 3.2rem;
  position: relative;
  transition: 0.2s;
}

.logo {
  height: 7.5rem;
}

.name:link,
.name:visited {
  color: #5fb1ad;
  text-decoration: none;
  font-size: 3.2rem;
  font-weight: 700;
  letter-spacing: -1px;
  font-family: 'Allerta Stencil', sans-serif;
}

.big-nav {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 3.2rem;
}

.main-nav-list {
  display: flex;
  list-style: none;
  gap: 4.8rem;
}

.main-nav-links:link,
.main-nav-links:visited {
  font-size: 2.4rem;
  text-decoration: none;
  color: #333;
  font-weight: 600;
  letter-spacing: -1px;
  transition: all 0.1s;
}

.main-nav-links:hover,
.main-nav-links:active {
  color: #5fb1ad;
  border-bottom: 2px solid currentColor;
}

/* STICKY NAVIGATION */

.sticky .main-header {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: 8rem;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 1.2rem 3.2rem rgb(0, 0, 0, 0.03);
}

.sticky .section-hero {
  margin-top: 80px !important;
}

/* MOBILE */

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.icon-mobile-nav[name='close-outline'] {
  display: none;
}

.section-hero {
  background-color: #eff7f7;
  padding: 4.8rem 0 8rem 0;
}

.header-hero {
  max-width: 130rem;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8rem;
  align-items: center;
  padding: 0 3.2rem;
}

.hero-text {
  color: #333;
  font-size: 8.6rem;
  font-weight: 700;
  letter-spacing: -5px;
  line-height: 0.9;
  margin-bottom: 4.8rem;
}

.hero-sub-text {
  color: #555;
  font-size: 3rem;

  /* letter-spacing: 1px; */
  line-height: 1.4;
}

.hero-img {
  width: 90%;
}

/* ********************* */
/* SERVICES */
/* ********************* */

.section-services {
  padding: 8rem 0 0;
  overflow: hidden;
}

.services {
  max-width: 100vw;
}

.service-text-box {
  padding: 0rem 6.4rem;
  padding-right: 3.2rem;
  width: 100%;
}

.appointment {
  font-size: 2rem;
  font-weight: 400;
}

.pilates-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.pilates-list li {
  font-size: 2.4rem;
  color: #555;
  display: flex;
  line-height: 1.4;
  align-items: center;
  gap: 1.2rem;
}

.pilates-list li span {
  text-align: left;
}

.service-check {
  font-size: 2.4rem;
  color: #5fb1ad;
}

.service-img-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  align-content: center;
  /* justify-items: center; */
  padding: 4.8rem 6.4rem;
}

.service-img-box::before {
  content: '';
  background-color: #dfefef;
  position: absolute;
  width: 200%;
  height: 100%;
  /* top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%); */
  z-index: -1;
}

.left.service-img-box::before {
  right: 0;
}

#left-img {
  width: 110%;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.075));
}

.service-img-box img {
  width: 85%;
}

.service-img-box-1 {
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
}

.service-img-box-2 {
  grid-column: 2;
  grid-row: 2;
  margin-top: -45%;
  margin-left: -45%;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.1));
}

.service-subheading {
  font-size: 4.4rem;
  font-weight: 700;
  color: #333;
  line-height: 1.2;
  letter-spacing: -0.5px;
  margin-bottom: 4.8rem;
}

/* PHYSIO */

.physio {
  background-image: linear-gradient(to right bottom, #afd8d6, #6fb9b5);
  margin: 0 4.8rem 4.8rem;
  border-radius: 5px;
}

.physio-container {
  display: grid;
  grid-template-columns: 40fr 60fr;
  align-items: center;
  column-gap: 2.4rem;
}

.physio-img-box {
  padding: 4.8rem;
  gap: 1.2rem;
}

.physio-img {
  overflow: hidden;
}

.physio-img img {
  width: 110%;
  transition: all 0.3s;
}

.physio-img img:hover {
  transform: scale(1.1);
}

.physio-text-container {
  padding: 8rem 2.4rem;
}

.physio-text-box {
  display: flex;
  gap: 3.2rem;
}

.physio .service-check {
  color: #555;
}

.physio-subheading {
  font-size: 3rem;
  color: #444;
  font-weight: 700;
  margin-bottom: 2.4rem;
}

.physio-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.physio-list li {
  font-size: 2rem;
  color: #555;
  display: flex;
  line-height: 1.6;
  align-items: center;
  gap: 1.2rem;
}

/* ********************* */
/* ABOUT */
/* ********************* */

.section-about-us {
  padding: 3.2rem 3.2rem 8rem;
  position: relative;
}

.about-us-content {
  align-items: center;
  margin-bottom: 4.8rem;
}

.about-us-content-textbox {
  width: 100%;
  font-size: 2rem;
  line-height: 1.8;
}

.about-us-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 0 2rem rgba(0, 0, 0, .2); */
}

.about-us-img {
  width: 75%;
  /* justify-self: center; */
  /* left: 50%;
    transform: translate(-50%, 0); */
  /* top: 20%;
    bottom: 20%; */
}

.about-us {
  display: flex;
  justify-content: space-between;
  padding-top: 4.8rem;
  align-items: center;
  margin-bottom: 8rem;
}

.about-us-text {
  font-size: 3rem;
  /* color: #091211; */
  color: #132323;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: -1.5px;
  display: flex;
  align-items: center;
  gap: 3.2rem;
}

.about-us-icon {
  height: 4rem;
  width: 4rem;
  /* color: #132323; */
  fill: #132323;
}

.about-us::before,
.about-us::after {
  content: '';
  background-color: #8fc8c6;
  position: absolute;
  width: 45%;
  height: 12.5%;
  bottom: 47%;
  z-index: -1;
}

.about-us::before {
  border-top-right-radius: 300px;
  border-bottom-right-radius: 300px;
  left: 0;
}

.about-us::after {
  border-top-left-radius: 300px;
  border-bottom-left-radius: 300px;
  right: 0;
}

.contact-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  gap: 4.8rem;
  font-size: 2rem;
  font-weight: 500;
}

.contact-heading {
  font-size: 3.6rem;
  font-weight: 700;
  color: #444;
  line-height: 1.2;
  margin-bottom: 4.8rem;
}

.contact {
  font-size: 2rem;
  width: 100%;
}

.contact-icon {
  font-size: 3rem;
  color: #5fb1ad;
  margin-bottom: 1.6rem;
}

.contact-link:link,
.contact-link:visited {
  text-decoration: none;
  color: #555;
  display: flex;
  align-items: center;
  gap: 1.2rem;
  transition: all 0.2s;
}

.contact-link:hover,
.contact-link:active {
  color: #333;
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.timing {
  font-weight: 400;
}

.timing-title {
  font-weight: 600;
  letter-spacing: -0.5px;
}

.home-visit {
  font-weight: 500;
}

.address {
  line-height: 1.6;
}

.address-link:link,
.address-link:visited {
  /* text-decoration: none; */
  color: inherit;
}

.shadow {
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 10px rgb(0, 0, 0, 0.05);
  padding: 2.4rem;
  border-radius: 10px;
  transition: all 0.3s;
  border: 1px solid rgb(34, 34, 34, 0);
}

.shadow:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 8px 20px rgb(0, 0, 0, 0.05);
}

/* ********************* */
/* FOOTER */
/* ********************* */

.footer {
  padding: 8rem 3.2rem;
  color: #767676;
  font-size: 1.4rem;
  line-height: 1.8;
  border-top: 1px solid #eee;
}

.footer-container {
  width: 70%;
}

.grid--footer {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3.2rem;
}

.footer-logo-img {
  width: 67%;
}

/* .footer-cons {
    padding: 0 2.4rem;
} */

.footer-heading {
  font-size: 1.8rem;
  font-weight: 600;
  color: #555;
  margin-bottom: 3.2rem;
}

.footer-link:link,
.footer-link:visited {
  color: #767676;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  letter-spacing: 0.5px;
}

.footer-link:hover,
.footer-link:active {
  color: #666;
}

.footer-con-text {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.footer-add {
  font-style: normal;
}

.footer-add a:link,
.footer-add a:visited {
  color: inherit;
}

.footer-add a:hover,
.footer-add a:active {
  color: inherit;
}

.footer-con {
  display: flex;
  flex-direction: column;
}

.footer-icons {
  font-size: 1.8rem;
  display: flex;
  gap: 1.6rem;
  margin-bottom: 0.8rem;
}

.footer-nav {
  margin-left: 3.2rem;
}

.footer-nav-list {
  list-style: none;
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.footer-nav-list .footer-link:link,
.footer-nav-list .footer-link:visited {
  font-weight: 500;
}

.copyright {
  color: #767676;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 1.6rem;
}

/* ********************* */
/* DARK MODE */
/* ********************* */

.darkmode {
  background-color: #fff;
  padding-right: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 3.6rem;
  right: 0;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  transform: translateX(55%);
  border-radius: 100px;
}

.dark .darkmode {
  background-color: #000;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.15);
  border: 2px solid #222;
}

.dark-mode-btn {
  height: 5.5rem;
  width: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #fff;
  border-radius: 50%;
}

.dark .dark-mode-btn {
  background-color: #000;
}

.dm-btn-icon {
  color: #5fb1ad;
  font-size: 4.4rem;
}

.dm-btn-icon[name='sunny-outline'] {
  display: none;
}

.dm-btn-icon[name='moon-outline'] {
  display: block;
}

.dark .dm-btn-icon[name='sunny-outline'] {
  display: block;
}

.dark .dm-btn-icon[name='moon-outline'] {
  display: none;
}

.dark {
  background-color: #000;
  color: #ababab;
}

.dark .icon-mobile-nav {
  color: #e9ecef;
}

.dark .main-header,
.dark .main-nav {
  background-color: #1e2424;
}

.dark .main-nav-links:link,
.dark .main-nav-links:visited {
  color: #fff;
}

.dark .main-nav-links:hover,
.dark .main-nav-links:active {
  color: #5fb1ad;
  border-bottom: 2px solid currentColor;
}

.dark .section-hero {
  background-color: #1e2424;
}

.dark .hero-text,
.dark .heading-secondary,
.dark .service-subheading {
  color: #e9ecef;
}

.dark .physio {
  background-image: linear-gradient(to right bottom, #6fb9b5, #4c8e8a);
}

.dark .physio-text-container .service-subheading {
  color: #333;
}

.dark .hero-sub-text {
  color: #adb5bd;
}

.dark .heading-tertiary {
  color: #cacaca;
}

.dark .pilates-list li {
  color: #ababab;
}

.dark .contact-link:link,
.dark .contact-link:visited {
  color: #ababab;
}

.dark .about-us::after,
.dark .about-us::before {
  background-color: #569f9c;
}

.dark .footer {
  color: #919191;
  border-top: 1px solid #282828;
}

.dark .footer-heading {
  color: #b5b5b5;
}

.dark .shadow {
  box-shadow: 0 0 0 rgb(255, 255, 255, 0.13);
  border: 1px solid #222;
}

.dark .shadow:hover {
  transform: translateY(-1.2rem);
  box-shadow: 0 0 0 rgb(34, 34, 34, 0.5);
  /* border: 2px solid #222; */
}

.dark .service-img-box::before {
  background-color: #569f9c;
}
